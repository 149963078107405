.image-collage-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Creates two columns */
    grid-template-rows: repeat(2, 1fr); /* Creates two rows */
    gap: 10px; /* Adjusts the gap between images */
    margin-top: 20px; /* Space above the grid */
  }

  .image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%; /* Ensures the image is not wider than its container */
    height: auto; /* Maintains the aspect ratio of the image */
    padding: 20px; /* Adds padding around the container */
    /* width: 100%; */
  }

  .image-wrapper {
    position: relative;
    cursor: pointer;
  }
  
  .image-wrapper.selected::after {
    content: '✓'; /* Tick mark */
    position: absolute;
    top: 5px; /* Adjust based on actual layout */
    right: 5px; /* Adjust based on actual layout */
    color: #fff; /* Tick mark color */
    font-size: 96px; /* Adjust based on actual layout */
    z-index: 1; /* Ensure it's above the image */
  }

  .image-wrapper.selected img {
    border: 8px solid #4CAF50; /* Highlight color */
    /* Optionally add more styles to highlight the selected image */
  }

  /* Responsive Image Style */
.responsive-image {
    max-width: 100%; /* Ensures the image is no wider than its container */
    height: auto; /* Maintains aspect ratio */
    display: block; /* Prevents inline default behavior, ensuring it takes up the full width available */
    margin-left: auto; /* Centers the image horizontally */
    margin-right: auto; /* Centers the image horizontally */
  }
  

  .collage-image {
    width: 100%; /* Makes image fill its cell */
    height: auto; /* Keeps image aspect ratio */
    object-fit: cover; /* Ensures images cover the cell without stretching */
  }