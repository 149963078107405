/* General styling for the image upload container */
.image-upload-container {
    text-align: center;
    margin: 20px auto;
}

/* Hide the actual file input */
.image-upload-input {
    display: none;
}

/* Styling for the custom button that will trigger the file input */
.custom-file-upload {
    display: inline-block;
    padding: 12px 28px;
    margin: 8px;
    border-radius: 4px;
    background-color: #007bff; /* Blue color */
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* Styling for the custom button when hovered */
.custom-file-upload:hover {
    background-color: #0056b3; /* Darker blue on hover */
    border-color: #004b9b;
    text-decoration: none; /* Remove underline from links if it's an 'a' tag */
}
