.card {
  width: calc(33% - 10px); /* Adjusts card width to fit two per row, accounting for gap */
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 20px;
  background-color: white;
  transition: transform 0.3s ease-in-out;
}
  
  .card:hover {
    transform: translateY(-5px);
  }
  
  /* Product Image */
  .thumbnail {
    width: 100%;
    height: auto;
    display: block;
  }
  
  /* Product Information */
  .info {
    padding: 10px;
    text-align: left;
  }
  
  .source-icon {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }
  
  .source-name, .price {
    display: block;
    margin-top: 5px;
    font-size: 14px;
    color: #333;
  }
  
  .price {
    font-weight: bold;
    color: #007bff;
  }
  
  /* Header for each product section */
  h2 {
    color: #333;
    font-size: 22px;
  }
  
  /* Style for the section divider */
  hr {
    border: none;
    height: 2px;
    background-color: #eee;
    margin: 20px 0;
  }

  .ratings {
    margin-top: 5px;
    display: flex;
    align-items: center;
  }
  
  .ratings span {
    margin-right: 5px;
  }
  
  .ratings span:last-child {
    font-size: 0.9em;
    color: #555;
  }