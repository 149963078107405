/* Overall list container adjustments remain the same */

/* Adjusted individual product item styling for wider display */
.product-item {
  background-color: #f9f9f9;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* This will line up cards to the start of the container */
  align-items: flex-start;
  gap: 20px; /* Adjust the gap as needed */
  padding: 20px;
  margin: 15px auto;
  width: 70%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

/* Hover effect, button styling, and hover effect remain the same */

/* Adjust product name alignment */
.product-content, .product-budget {
  flex: 1; /* Allow each side to grow */
  min-width: 200px; /* Minimum width before wrapping */
}

.product-content {
  padding-right: 20px; /* Adjust if needed to prevent text from wrapping too closely to the budget */
}

.product-item h2 {
  color: #333;
  margin: 0; /* Adjusted to remove bottom margin if it affects alignment */
  padding: 0; /* Ensure no padding is causing misalignment */
}

.product-budget {
  text-align: right; /* Align the budget text to the right */
  font-size: 16px;
  color: #007bff; /* Adjusted styles remain */
}

/* Responsive adjustments and description text styling remain the same */
