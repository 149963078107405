.fancy-button {
    background-color: #4CAF50; /* Green background */
    border: none;
    color: white; /* White text */
    padding: 15px 32px; /* Some padding */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 8px; /* Rounded corners */
  }
  
  .fancy-button:hover {
    background-color: #45a049; /* Darker green on hover */
  }
  
  .upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .file-name {
    display: block; /* Or inline-block, depending on your layout */
    margin-top: 10px; /* Adjust as needed */
    font-size: 14px; /* Adjust as needed */
  }

  .slider {
    -webkit-appearance: none;
    width: 50%;
    height: 15px;
    border-radius: 5px;   
    background: green;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }
  
  .slider:hover {
    opacity: 1;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: var(--primary-color);
    cursor: pointer;
  }
  
  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: var(--primary-color);
    cursor: pointer;
  }
  
  