input[type="text"],
input[type="number"] {
  font-size: 16px;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  display: block;
  width: calc(100% - 30px); /* Adjust width based on padding */
}

.large-text-input {
    width: 300px; /* Fill the container, or set a fixed width */
    height: 150px; /* Adjust the height as needed */
    padding: 10px; /* Space inside the box */
    border: 2px solid #4A90E2; /* A colored border */
    border-radius: 8px; /* Rounded corners */
    background-color: #FAFAFA; /* Light background color */
    color: #333333; /* Text color */
    font-size: 16px; /* Readable font size */
    font-family: 'Arial', sans-serif; /* A nice, readable font */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    resize: vertical; /* Only vertical resizing */
    outline: none; /* Remove default focus outline */
    transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* Smooth transitions */
  }
  
  .large-text-input:focus {
    border-color: #FFA726; /* Brighter border color when focused */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); /* Enhanced shadow on focus */
  }

.slider {
    -webkit-appearance: none;
    width: 50%;
    height: 15px;
    border-radius: 5px;   
    background: green;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }
  
  .slider:hover {
    opacity: 1;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: var(--primary-color);
    cursor: pointer;
  }
  
  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: var(--primary-color);
    cursor: pointer;
  }

  .prompt-text {
    font-family: 'Montserrat', sans-serif;
    font-size: 24px; /* Adjust size as needed */
    font-weight: 600; /* Semi-bold */
    color: #333; /* Dark grey for softer contrast */
    margin-top: 20px;
    margin-bottom: 20px; /* Space before the input box */
    text-align: center; /* Center if it fits your design */
  }
  
  .prompt-text-smaller {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px; /* Adjust size as needed */
    font-weight: 600; /* Semi-bold */
    color: #333; /* Dark grey for softer contrast */
    margin-top: 20px;
    margin-bottom: 20px; /* Space before the input box */
    text-align: center; /* Center if it fits your design */
  }