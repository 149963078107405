@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.App {
  font-family: Arial, sans-serif;
  text-align: center;
  margin: 0;
  padding: 20px;
}

/* Define your color palette */
:root {
  --primary-color: #5D93E1; /* Blue shade */
  --secondary-color: #FFC107; /* Amber shade */
  --accent-color: #4CAF50; /* Green shade */
  --text-color: #333;
  --background-color: #f4f4f4;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Roboto', 'Open Sans', sans-serif;
}

h1, h2, h3 {
  font-weight: 700; /* Use the bolder weight for headings */
}

p, input, button, label {
  font-weight: 400; /* Regular weight for other text */
}

h1 {
  color: #333;
}

form {
  margin: 20px 0;
}

label {
  display: block;
  margin-bottom: 10px;
  font-size: 18px;
  color: #555;
}



.button-container {
  text-align: center; /* Centers the button horizontally */
  margin-top: 10px; /* Adds some space between the textarea and the button */
  display: flex;
  justify-content: center;
  width: 100%; /* Ensures the wrapper extends full width */
}

button {
  font-size: 18px;
  padding: 15px;
  border: none;
  border-radius: 5px;
  background-image: linear-gradient(to right, #6db3f2, #1e69de);
  color: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: block;
}

button:hover {
  background-image: linear-gradient(to right, #5ca6db, #1a5cad);
}


.input-field {
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  border: 2px solid var(--primary-color);
  border-radius: 4px;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.input-field:focus {
  border-color: var(--secondary-color);
}

hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
  margin: 20px 0;
}

.item-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.card {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  width: 200px;
  text-align: left;
}

.thumbnail {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.info {
  margin-top: 10px;
}

.source-icon {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

.source-name {
  margin-left: 5px;
  font-size: 14px;
}

.price {
  display: block;
  margin-top: 5px;
  color: #007bff;
  font-weight: bold;
}



/* Responsive Image Style */
.responsive-image {
  max-width: 100%; /* Ensures the image is no wider than its container */
  height: auto; /* Maintains aspect ratio */
  display: block; /* Prevents inline default behavior, ensuring it takes up the full width available */
  margin-left: auto; /* Centers the image horizontally */
  margin-right: auto; /* Centers the image horizontally */
}

.header {
  font-size: 2.5em;
  color: var(--primary-color);
  text-align: center;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.2);
  margin-bottom: 1rem;
}

.fancy-button {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: block; /* Ensures button is a block-level element */
  font-size: 16px;
  margin-top: 20px; /* Adds space between the image and the button */
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}

.fancy-button:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.3);
}

.loading {
  font-size: 30px;
  text-align: center;
  color: blue;
}

.beautiful-title {
  color: #4a4a4a; /* Dark gray color */
  font-family: 'Arial', sans-serif;
  font-size: 3em; /* Larger font size */
  margin-bottom: 0.5em; /* Space below the title */
  text-decoration: underline; /* Underline the title */
}

.small-beautiful-text {
  color: #6a6a6a; /* Lighter gray color */
  font-family: 'Arial', sans-serif;
  font-size: 2em; /* Smaller font size */
  margin-top: 0.5em; /* Space above the text */
  margin-bottom: 1em; /* Space below the text */
  font-style: italic; /* Italicize the text */
}



.small-text-input {
  width: 100px; /* Fill the container, or set a fixed width */
  height: 30px; /* Adjust the height as needed */
  padding: 10px; /* Space inside the box */
  border: 2px solid #4A90E2; /* A colored border */
  border-radius: 8px; /* Rounded corners */
  background-color: #FAFAFA; /* Light background color */
  color: #333333; /* Text color */
  font-size: 16px; /* Readable font size */
  font-family: 'Arial', sans-serif; /* A nice, readable font */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  resize: vertical; /* Only vertical resizing */
  outline: none; /* Remove default focus outline */
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* Smooth transitions */
}

.small-text-input:focus {
  border-color: #FFA726; /* Brighter border color when focused */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); /* Enhanced shadow on focus */
}

.button-wrapper {
  display: flex;
  justify-content: center;
  width: 100%; /* Ensures the wrapper extends full width */
}

.image-wrapper {
  position: relative;
  cursor: pointer;
}

.image-wrapper.selected::after {
  content: '✓'; /* Tick mark */
  position: absolute;
  top: 5px; /* Adjust based on actual layout */
  right: 5px; /* Adjust based on actual layout */
  color: #fff; /* Tick mark color */
  font-size: 96px; /* Adjust based on actual layout */
  z-index: 1; /* Ensure it's above the image */
}

.image-wrapper.selected img {
  border: 8px solid #4CAF50; /* Highlight color */
  /* Optionally add more styles to highlight the selected image */
}

.label-example-prompt {
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 8px;
  display: block; /* Ensures the text starts on a new line */
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fade-in {
  animation: fadeIn 1s ease-in-out;
}

.input-field, .button {
  animation: fadeIn 1s ease-in-out;
}

.input-field:hover, .button:hover {
  transform: scale(1.02);
}

.header {
  font-family: 'Playfair Display', serif; /* A more decorative font for headings */
  letter-spacing: 1px;
}

.green-divider {
  height: 3px; /* Adjust the thickness of the line */
  background-color: green; /* Color of the line */
  width: 100%; /* Makes the line stretch across the full width of its container */
  margin-top: 20px;
}

.total-budget {
  font-size: 24px;
  font-weight: bold;
  margin: 20px 0;
}











