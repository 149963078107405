.scrolling-wrapper {
    display: flex;
    overflow-x: hidden; /* Hide the overflow */
    width: 100%; /* The width of the container */
    position: relative;
  }
  
  .scrolling-image {
    width: 100%; /* Each image takes the full width of the container */
    flex: 0 0 100%; /* Do not allow the image to grow or shrink */
    transition: transform 0.3s ease-in-out;
    position: absolute; /* Position images absolutely within the parent */
    left: 0; /* Align images to the left */
  }
  
  /* Style for the dots indicator */
  .dots {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  
  .dot {
    cursor: pointer;
    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.3s ease-in-out;
  }
  
  .dot.active {
    background-color: #717171;
  }
  