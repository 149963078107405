.product-item-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    margin-top: 40px; /* Adjusted for visual spacing and line */
    padding-top: 20px; /* Space for the pseudo-element */
  }
  
  .product-item-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0; /* Start from the very left */
    width: 100%; /* Extend across the full width of the container */
    height: 2px; /* Line thickness */
    background-color: #007BFF; /* Solid blue color */
  }
  
  
  .product-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .toggle-button {
    background-color: #007BFF;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    font-size: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s ease-in-out;
    margin-top: 10px; /* Adds space above the button */
  }
  
  .toggle-button:hover {
    background-color: #0056b3;
  }
  
  .labor-required {
    position: absolute;
    bottom: -20px; /* Adjust this value to move it further down */
    right: 0;
    padding: 8px;
    background-color: blue; /* Change the background color to blue */
    color: white; /* Change the text color to white */
    border: 1px solid #ccc;
    border-radius: 4px;
  }

.labor-cost {
  margin: 10px 0;
  /* Adds some space between the budget and the thumbnails */
  padding: 8px;
  background-color: green;
  /* You can choose a different color if you like */
  color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-weight: bold;
  text-align: right;
  /* Aligns the text to the right to match the budget */
}

.material-cost {
  margin: 10px 0;
  /* Adds some space between the budget and the thumbnails */
  padding: 8px;
  background-color: green;
  /* You can choose a different color if you like */
  color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-weight: bold;
  text-align: right;
  /* Aligns the text to the right to match the budget */
}