/* CityDropdown.css */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    font-family: 'Arial, sans-serif';
  }
  
  h3 {
    color: #333;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  select {
    width: 200px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease;
    margin-bottom: 20px; /* Added bottom margin */
  }
  
  select:focus {
    border-color: #007BFF;
    outline: none;
  }
  