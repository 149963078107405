@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.Introduction {
  font-family: Arial, sans-serif;
  text-align: center;
  margin: 0;
  margin-top: -10px;
}

/* Define your color palette */
:root {
    --primary-color: #5D93E1; /* Blue shade */
    --secondary-color: #FFC107; /* Amber shade */
    --accent-color: #4CAF50; /* Green shade */
    --text-color: #333;
    --background-color: #f4f4f4;
}

.gradient-text {
    background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.header {
    font-size: 2.5em;
    color: var(--primary-color);
    text-align: center;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.2);
    margin-bottom: 1rem;
}

.header {
    font-family: 'Playfair Display', serif; /* A more decorative font for headings */
    letter-spacing: 1px;
}

.description {
    color: var(--text-color);
    font-size: 1rem; /* Smaller base font size for mobile */
    line-height: 1.6;
    text-align: center;
    max-width: 600px;
    margin: 0 auto 2rem;
    padding: 0 20px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    transition: transform 0.2s ease-in-out;
    color: #003366; /* Dark blue text color */
}
  
  /* Larger, more colorful text on desktop */
@media (min-width: 768px) {
    .description {
      font-size: 2rem; /* Larger font size for desktop */
      
      max-width: 1300px; /* Wider on desktop */
    }
  }

.description:hover {
    transform: scale(1.02);
}

.bubbly-text {
    font-family: 'Helvetica Neue', sans-serif; /* A clean, modern, and versatile font */
    font-weight: bold; /* Makes the text stand out */
    font-size: 1.5rem; /* Slightly larger than the surrounding text */
    color: #D32F2F; /* A vibrant, energetic color */
    transition: all 0.3s ease-in-out; /* Smooth transition for any changes */
    margin-bottom: 20px;
}
  
.bubbly-text:hover {
    transform: translateY(-3px); /* A subtle lift effect on hover for interactivity */
    color: #F44336; /* A slightly lighter shade when hovering for a dynamic change */
}

.green-divider {
    height: 3px; /* Adjust the thickness of the line */
    background-color: green; /* Color of the line */
    width: 100%; /* Makes the line stretch across the full width of its container */
    margin-top: 20px;
  }

  