/* FloatingButton.css */

.floating-button {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000; /* Ensure it stays on top of other content */
    padding: 10px 20px;
    background-color: #007bff; /* Example blue background */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Add some shadow for better visibility */
    transition: background-color 0.3s;
  }
  
  .floating-button:hover {
    background-color: #0056b3; /* Darken button on hover for feedback */
  }
  